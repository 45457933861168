"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_property_decorator_1 = require("vue-property-decorator");
var BaseView_1 = require("@shared/views/BaseView");
/** A single deck in a list of decks */
var DeckItem = /** @class */ (function (_super) {
    __extends(DeckItem, _super);
    function DeckItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /** The url of this decks image */
        _this.imageUrl = '';
        return _this;
    }
    /** Fires when the view is mounted */
    DeckItem.prototype.mounted = function () {
        this.imageUrl = "https://lurker.blob.core.windows.net/decks/d_deck_" + this.model.id + ".jpg";
    };
    __decorate([
        vue_property_decorator_1.Prop({ type: Object })
    ], DeckItem.prototype, "model", void 0);
    __decorate([
        vue_property_decorator_1.Prop({ default: true })
    ], DeckItem.prototype, "showCompany", void 0);
    __decorate([
        vue_property_decorator_1.Prop({ default: true })
    ], DeckItem.prototype, "showYear", void 0);
    DeckItem = __decorate([
        vue_property_decorator_1.Component({})
    ], DeckItem);
    return DeckItem;
}(BaseView_1.BaseView));
exports.default = DeckItem;
