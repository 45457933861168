



























import { Component, Prop, Watch } from 'vue-property-decorator';
import { BaseView } from '@shared/views/BaseView';
import { Deck, DeckSearchRequest, PagedResult } from '@shared/models';
import DeckItem from '@client/views/products/decks/DeckItem.vue';

@Component({
	components: {
		DeckItem,
	},
})
export default class ArtistDecks extends BaseView {

	@Prop()
	public modelId: string;
	public list: PagedResult<Deck> = new PagedResult<Deck>();
	public criteria: DeckSearchRequest = new DeckSearchRequest({ pageIndex: 0, pageSize: 32, sortBy: 'year', sortDirection: 'asc' });

	@Watch('modelId')
	public async watchModelId() {
		await this.refresh();
	}

	@Watch('$route', { immediate: true, deep: true })
	public async refresh() {
		if (!this.modelId) { return; }
		this.loading = true;
		try {
			this.criteria = new DeckSearchRequest(this.$route.query);
			this.criteria.artistIds = [ this.modelId ];
			this.list = await this.$api.deckService.search(this.criteria);
		} catch (err) {
			this.errorHandler.handle(err, 'An error occurred while loading this artists deck list');
		} finally {
			this.loading = false;
		}
	}

	/** Fires when the page is changed */
	public async pageChanged(page: number) {
		this.criteria.pageIndex = (page - 1);
		await this.filter(false);
	}

	/** Applies the specified filter and redirects to the new page with the correct query parameters */
	public async filter(resetPage: boolean) {
		this.scroll.scrollToTop();
		if (resetPage) { this.criteria.pageIndex = 0; }
		this.$router.push({ name: 'artist-decks', params: { id: this.modelId }, query: this.criteria.collapse() }).catch(() => { /* INTENTIONALLY EMPTY */ });
		await this.refresh();
	}



}

